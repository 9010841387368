<template>
    <div class="login">
        <div class="container">
            <div class="wrapper">
                <div class="text-center">
                    <img alt="logo" src="../assets/logo.png" width="175" height="59">
                    <h1>PIT SYSTEM</h1>
                </div>
                <a-form :form="form" class="login-form" @submit="handleSubmit">
                    <a-form-item>
                        <a-input size="large" v-decorator="['email', { rules: [{ required: true, message: 'Please input your email!' }, { type: 'email', message: 'Please input valid email!' }] },]" type="email" placeholder="Email" >
                            <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)" />
                        </a-input>
                    </a-form-item>
                    <a-form-item>
                        <a-input-password size="large" v-decorator="['password', { rules: [{ required: true, message: 'Please input your Password!' }] }, ]" type="password" placeholder="Password">
                            <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)" />
                        </a-input-password>
                    </a-form-item>
                    <a-form-item>
                        <a-button type="primary" html-type="submit" block :disabled="disabled">Log in</a-button>
                    </a-form-item>
                </a-form>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data: function() {
            return {
                disabled: false
            }
        },
        beforeCreate() {
            this.form = this.$form.createForm(this, { name: 'login_form' });
        },
        mounted() {
            document.title = 'PIT System - Login';
        },
        methods: {
            handleSubmit(e) {
                e.preventDefault();
                this.disabled = true;

                this.form.validateFields(( err, values ) => {
                    if ( !err ) {
                        this.$http.post('/users/login', values).then( response => {
                            if ( response.data.success == true ) {
                                localStorage.setItem('access_token', response.data.access_token);
                                localStorage.setItem('refresh_token', response.data.refresh_token);
                                this.$http.defaults.headers.common['Authorization'] = 'Bearer '+ response.data.access_token;
                                this.$router.push('/');
                            } else {
                                this.$message.error( response.data.message );
                                this.disabled = false;
                            }
                        } );
                    }
                });
            }
        }
    }
</script>
<style lang="scss">
    .login {
        background-image: url('../assets/pattern.svg');
        background-size: cover;
        background-color: #1890FF;
        min-height: 100vh;
        display: flex;
        align-items: center;

        .container {
            max-width: 480px;
            width: 90%;
            margin: 0 auto;

            .wrapper {
                background-color: #F5F5F5;
                margin: 32px auto;
                padding: 48px 10%;
            }

            h1 {
                font-size: 40px;
                color: #0170C4;
                font-weight: bold;
                margin-top: 20px;
            }
        }
    }

    .text-center {
        text-align: center;
    }
</style>
